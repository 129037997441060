@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.textContainer {
    width: 80%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.iconContainerobj {
    display: flex;
    margin: auto;
    text-align: center;
}

.buttonWidth {
    min-width: 30rem;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: solid #ed6e18 1.5px;
}

.sliderContainer {
    padding: 2rem;
    border: solid #ed6e18 1.5px;
    border-radius: 2rem;
}

.objectiveContainer {
    width: 80%;
    height: 75vh;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    margin: 2rem auto 0 auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.bigIcon {
    margin: auto;
    width: 35%;
    min-width: 20rem;
    min-height: 15rem;
    height: 35%;
}

.dashIcon {
    cursor: pointer;
    position: relative;
    transform: rotate(90deg);
    color: $colorFlamingo;
    width: 3.5rem;
    height: 1.5rem;
}

.arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.arrowContainer {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    width: 60%;
    white-space: nowrap;
    align-items: baseline;
}

.ButtonBlock {
    align-self: center;
    position: absolute;
    bottom: 20px;
}

.title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.mainText {
    @extend .title;
    text-align: left;
}

.textHelper {
    @extend .textContainer;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
