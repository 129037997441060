@import '../../../styles/colors';
@import '../../../styles/rwd';

.card-section {
    height: 100%;
    overflow: auto;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.stretch {
    margin-left: -2rem;
    margin-right: -2rem;

    .title-bar {
        margin: 0 2rem 2rem;
    }
}
.arrowCenter {
    position: absolute;
}
.title-bar {
    position: sticky;
    top: 0;
    background: $colorWhite;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $colorAlto;
    z-index: 10;

    @include media('<=tablet') {
        display: block;
        padding-top: 2rem;
    }
}

.content {
    flex: 1 1 auto;
}

// This probably works only with 2 children breadcrumbs, but this is the design
.breadcrumb {
    font-family: Lato !important;
    font-weight: bold;
    font-size: 1.6rem;
    display: inline-block;
    margin: 0;

    @include media('<=tablet') {
        display: block;
        text-align: center;

        &:first-of-type {
            color: $colorSilverChalice;
            font-size: 1.2rem;
        }
    }
}

.dash-icon {
    transform: rotate(-90deg) translateX(-4px);
    width: 1rem;
    height: 1rem;
    margin: 0 1rem;
    color: $colorSilverChalice;

    &.last {
        display: none;
    }

    @include media('<=tablet') {
        display: none;
    }
}

.close-icon {
    cursor: pointer;
    margin-left: auto;
    color: $colorSilverChalice;

    @include media('<=tablet') {
        display: none;
    }
}

.mobile-back-button {
    display: none;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    height: 3rem;
    width: 3rem;
    padding: 1rem;

    @include media('<=tablet') {
        display: block;
    }
}
