@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.blockText {
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    @include media('<=phone') {
        font-weight: 500;
        height: 12px;
        font-size: 10px;
        line-height: 12px;
    }
}

.periodBlockIcon {
    align-items: center;
    place-content: center;
    display: flex;
    width: 9rem;
    height: 5rem;
    border: solid #d9d9d9 1px;
    cursor: pointer;

    @include media('<=phone') {
        width: 5.5rem;
        height: 3rem;
    }
}

.flexGrid {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.periodBlockIconSelected {
    @extend .periodBlockIcon;
    background-color: #fdf0e8;
    color: $colorFlamingo;
    cursor: pointer;
}

.titlePeriod {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2rem;

    @include media('<=phone') {
        font-weight: 700;
        font-size: 13px;
        line-height: 17px;
    }
}
