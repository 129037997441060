@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.arrowContainer {
    display: flex;
    flex-flow: column;
    align-items: end;
    text-align: right;
    white-space: nowrap;
}

.arrowContainer0 {
    display: flex;
    white-space: nowrap;
}

.arrowTextContainer {
    display: flex;
    white-space: nowrap;
    align-items: baseline;
}

.arrowTextEmptyDiv {
    width: +15%;

    @include media('<=phone') {
        width: inherit;
    }
}

.arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #e8e8e8;
}

.commonText {
    padding-bottom: 2.5%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;

    @include media('<=phone') {
        padding-bottom: 5%;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
    }
}

.flexGrid {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.title {
    margin-bottom: 1rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    @include media('<=phone') {
        margin-bottom: 1rem;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
    }
}

@mixin progress($value) {
    transition: 0.5s;

    @if (($value)) {
        background: linear-gradient(270deg, #99ca3a 0%, #b1f134 100%);

        &:hover {
            --x: 100%;
        }
    } @else {
        background: linear-gradient(90deg, $buttonC1, $buttonC2 51%, $buttonC1)
            var(--x, 0) /
            200%;

        &:hover {
            --x: 100%;
        }
    }

    width: 10%;
    height: 1.5rem;
    border-radius: 2rem;
}

.progressBarNotCompleted {
    @include progress(false);
}

.progressBarCompleted {
    @include progress(true);
}

.progressContainer {
    height: 1.5rem;
    background-color: #e8e8e8;
    border-radius: 2rem;
}

.width70 {
    width: 70%;
    padding-bottom: 0.5rem;
}
