@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.commonText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: $colorFlamingo;
    margin-top: 2rem;
    cursor: pointer;
}
.monthBlock {
    width: 15%;
    height: 5vh;
    justify-content: center;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #a6a6a6;
    border-radius: 1rem;
    &:hover {
        background-color: #fdf0e8;
        color: black;
    }
    @include media('<=phone') {
        width: 45%;
        height: 4vh;
    }
}
.monthBlockSelected {
    @extend .monthBlock;
    background-color: #fb8c00;
    color: black;
    &:hover {
        background-color: #fb8c00;
        color: black;
    }
}
.monthGrid {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    column-gap: 1rem;
    flex-wrap: wrap;
}
