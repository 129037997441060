@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.SubText {
    font-size: medium;
    color: #586066;
    padding-top: 0.2rem;

    @include media('<=phone') {
        font-size: x-small;
        color: #586066;
        padding-top: 0.2rem;
    }
}

.sumaryBlock {
    background-color: #ed6e1810;
    width: 30%;
    height: 8vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    padding: 7px;

    @include media('<=phone') {
        height: 6.5vh;
        border-radius: 0.5rem;
        width: 30%;
        padding: 4px;
        text-align: unset;
    }
}

.sumaryTextBlock {
    font-family: Lato !important;
    font-size: medium;
    display: flex;
    flex-flow: column;
    text-align: left;

    @include media('<=phone') {
        font-size: 12px;
        display: flex;
        flex-flow: column;
        text-align: left;
    }
}
