@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.icon {
    position: absolute;
    top: 20%;
    left: 46%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: #e8e8e8;
    z-index: 10;
    border-radius: 3rem;

    @include media('<=phone') {
        top: 28%;
        left: 41%;
    }
}

.iconSize {
    width: 3rem;
    height: 3rem;
}

.textHelper {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    /* ORANGE */
    color: #ed6e18;
    position: absolute;
    top: 57%;
    right: 42%;

    @include media('<=phone') {
        width: 40%;
        top: 55%;
        right: 30%;
    }
}