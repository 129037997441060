@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.dataBlockContainer {
    display: flex;
    flex-flow: column;
    min-width: 100%;
}
.headerContainerNew {
    height: 12vh;
    padding: 0.5rem 0.5rem 0px 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.chartContainer {
    height: 40vh;
    padding: 0.5rem 0.5rem 0px 0.5rem;
    width: 100%;

    @include media('<=phone') {
        margin: auto;
    }
}

.doughnutContainer {
    padding: 0px 10px 0px;
    @extend .chartContainer;
    display: flex;
}

.displayFlex {
    display: flex;
    align-items: center;
    justify-content: unset;

    @include media('<=phone') {
        justify-content: space-between;
    }
}

.headerContainer {
    text-align: center;
    font-size: medium;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include media('<=phone') {
        font-size: small;
        justify-content: 'unset';
    }
}

.doughnut {
    align-self: center;
    margin-left: 5%;
    width: 30%;
    @include media('<=phone') {
        margin-left: 10%;
        width: 40%;
        height: 30%;
    }
}

.evolutionTextContainer {
    display: flex;
    margin: 2rem;
    gap: 1rem;
    padding: 0.5rem;

    @include media('<=phone') {
        margin: auto;
    }
}

.evolutionText {
    font-size: medium;
    margin: auto;
    display: flex;
    gap: 0.2rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    @include media('<=phone') {
        margin: 0.3rem;
        gap: 0.2rem;
        font-size: small;
        display: flex;
        text-align: center;
    }
}

.gaugeContainer {
    width: 55%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ed6e1810;
    width: 5rem;
    height: 5rem;
    text-align: -webkit-center;
    border-radius: 1rem;
    margin: 1rem;

    @include media('<=phone') {
        padding-top: 0.7rem;
        margin: 0;
        width: 3.5rem;
        height: 3.5rem;
    }
}

.icon {
    width: 2.2rem;
    height: 2.2rem;

    @include media('<=phone') {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.roundedIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fdf0e8;
    width: 5rem;
    height: 5rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 3rem;

    @include media('<=phone') {
        width: 3rem;
        height: 3rem;
    }
}
