@import '../../styles/colors';
@import '../../styles/rwd';

.button {
    width: 50%;

    @include media('<=phone') {
        width: -webkit-fill-available;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-evenly;
    @include media('<=phone') {
        text-align: unset;
        gap: 1rem;
    }
}

.activityContainer {
    display: flex;
    margin: auto;
    flex-direction: column;
    text-align: center;
    width: 85%;
    height: 40vh;
    @include media('<=phone') {
        justify-content: 'unset';
        text-align: unset;
        width: 85%;
        height: unset;
        gap: 1.5rem;
    }
}

.calendarBlock {
    display: flex;
    vertical-align: middle;
    align-items: center;
    color: $colorFlamingo;
    font-weight: bold;
    cursor: pointer;
    @include media('<=phone') {
    }
}

.graphBlockContainer {
    height: 55vh;
    margin: auto;
    width: 85%;
    border-radius: 6px;
    background-color: #ebc9a020;
    padding: 1rem;
    @include media('<=phone') {
        height: unset;
        margin-bottom: 3rem;
    }
}

.doughnutContainer {
    width: 60%;
    margin: auto;

    @include media('<=phone') {
        width: 90%;
    }
}

.icon {
    margin: auto;
    display: flex;
    width: fit-content;
}
.textDatePicker {
    font-family: Lato;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    @include media('<=phone') {
        font-weight: bold;
        height: 14px;
        font-size: 12px;
        line-height: 12px;
    }
}
.iconUrl {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    padding: 0.2rem;

    @include media('<=phone') {
        width: 5.5rem;
        height: 5.5rem;
    }
}

.dashIcon {
    cursor: pointer;
    position: relative;
    transform: rotate(90deg);
    color: $colorFlamingo;
    width: 3.5rem;
    height: 1.5rem;
}

.iconContainer {
    border-radius: 4rem;
    width: 6rem;

    @include media('<=phone') {
        width: 5.5rem;
    }
}

.trackerIcon {
    padding: 1rem;

    @include media('<=phone') {
        padding: 0.5rem;
    }
}

.flexGrid {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    @include media('<=phone') {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.margin1rem {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.gridCenter {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.pt2 {
    flex-direction: column;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
}

.textModal {
    height: 10vh;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.title {
    font-family: Lato !important;
    font-style: Bold;
    font-size: 20px;
    line-height: 28px;
    line-height: 140%;
    vertical-align: Top;
    text-align: center;
}

.titleToday {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 0.5rem;
    text-align: start;
    @include media('<=phone') {
        margin: 0.4rem auto 0.4rem;
        font-weight: 800;
        font-size: 19px;
        line-height: 18.8px;
    }
}
.titlePeriod {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 0.5rem;
    text-align: start;
    margin-top: 2rem;
    @include media('<=phone') {
        margin: 0.4rem auto 0.4rem;
        font-weight: 800;
        margin-top: 4rem;
        font-size: 19px;
        line-height: 18.8px;
    }
}

.trophyIconContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    background-color: #e8e8e8;
    margin-top: 1rem;

    @include media('<=phone') {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 3rem;
        padding: 0.9rem;
    }
}

.trophyIconContainerCompleted {
    @extend .trophyIconContainer;
    background-color: #ed6e1810;
}

.settingIconContainer {
    width: 3.5rem;
    height: auto;
    cursor: pointer;

    @include media('<=phone') {
        width: 2.5rem;
    }
}

.svg1 {
    height: auto;
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 5rem;
    border-radius: 3rem;
}

.svg2 {
    display: block;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    height: auto;
    z-index: 3;

    @include media('<=phone') {
        padding: 0;
        width: 4.5rem;
        height: auto;
    }
}

.subtitle {
    font-family: Lato !important;
    font-style: Regular;
    font-size: 16px;
    line-height: 21px;
    line-height: 130%;
    vertical-align: Top;
}

.resumeText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    @include media('<=phone') {
        font-style: normal;
        margin: 0.4rem auto 0.4rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

.periodBlockIcon {
    align-items: center;
    place-content: center;
    display: flex;
    width: 6rem;
    height: 4rem;
    border: solid #d9d9d9 1px;
}

.periodBlockIconSelected {
    @extend .periodBlockIcon;
    background-color: #fdf0e8;
    color: $colorFlamingo;
}

.textContainer {
    width: 80%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
